/*
Replace
dish with your resource Name :) Take care about different writings!
/!\ Dish dish Dishes dishes /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const dishSlice = createSlice({
  name: "dishes",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
  },
});

export const {
  list: listDishes,
  show: showDish,
  create: createDish,
  update: updateDish,
} = dishSlice.actions;

export const actions = dishSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const dishes = (state) =>
  state.dishes.idsList.map((id) => state.dishes.dictionary[id]);
export const dish = (state) =>
  state.dishes.showId && state.dishes.dictionary[state.dishes.showId];
export const dishAttributes = (state) => dish(state)?.attributes;
export const dishRelationships = (state) => dish(state)?.relationships;
export const dishId = (state) => state.dishes.showId;

export const getDish = createSelector(
  (state) => state.dishes.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getDishAttributes = createSelector(
  (state) => state.dishes.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getDishRelationships = createSelector(
  (state) => state.dishes.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getDishes = createSelector(
  (state) => state.dishes.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export const getDishIdsForDate = createSelector(
  (state) => state.dishes.dictionary,
  (_, day) => day,
  (dictionary, day) => {
    return Object.entries(dictionary)
      .filter((_dish) => _dish[1].attributes.date_of_serving === day)
      .map((_dish) => _dish[0]);
  }
);

export const getDishIdsForDateAndTimeslot = createSelector(
  (state) => state.dishes.dictionary,
  (_, singleDayId) => singleDayId,
  (_, timeslotId) => timeslotId,
  (dictionary, { singleDayId, timeslotId }) => {
    return Object.entries(dictionary)
      .filter(
        (_dish) =>
          _dish[1].attributes.date_of_serving === singleDayId &&
          _dish[1].relationships.timeslot.data.id === timeslotId
      )
      .map((_dish) => _dish[0]);
  }
);

export const getDishAllergensAttributes = createSelector(
  (state) => state.dishes.dictionary,
  (state) => state.allergens.dictionary,
  (_, id) => id,
  (dishesDictionary, allergensDictionary, id) => {
    const dish = dishesDictionary[id];
    const allergens = dish?.relationships?.allergens?.data?.map(
      (allergen) => allergensDictionary[allergen.id]?.attributes
    );

    return allergens;
  }
);

export default dishSlice.reducer;
